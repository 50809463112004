import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../api/auth";
import { toast, ToastContainer, Zoom } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"
import logo from '../img/logo/mobis_CI.png'
import axios from 'axios';
import store from '../redux/store';
import { setToken } from '../redux/slices/userSlice';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authConfig';

function Login() {

  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (activeAccount) {
      const request = {
        ...loginRequest,
        account: accounts[0]
      };

      instance.acquireTokenSilent(request).then(response => {
        const accessToken = response.accessToken;
        store.dispatch(
          setToken({ token: accessToken })
        );
        setTimeout(async () => {
          await login().catch(err => {
            console.log("LOGIN API ERROR", err);
          });

          navigate("/mes/menu");
        }, 100);
      }).catch(error => {
        console.log("[ERROR] acquireTokenSilent, ", error);
      });
    }
  }, [activeAccount]);

  const handleRedirect = () => {
    store.dispatch(
      setToken({ token: "" })
    );
    instance.loginRedirect({
      ...loginRequest,
    }).catch(error =>
      console.log(error)
    );
  }


  return (
    <>
      <ToastContainer draggable={false} transition={Zoom} autoClose={3000} style={{ width: "100%", textAlign: "center", fontSize: "100%" }} hideProgressBar />
      <UnauthenticatedTemplate>
        <div className="min-h-[calc(100vh)] flex flex-col items-center justify-center login-bg" >
          <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
            <img src={logo} alt="Logo" />
            <div className="mt-10 mb-1 text-lg text-center">
              MOBIS NORTH AMERICA
            </div>
            <div className="relative h-px bg-gray-300" />
            <div className="mt-5">
              <div className="flex w-full">
                <button type="button"
                  className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in" onClick={handleRedirect}>
                  <span className="mr-2 uppercase">Sign In</span>
                  <span>
                    <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </>
  )
}

export default Login;
