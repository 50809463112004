import React, { useEffect, useState } from 'react';

import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import Header from '../component/Header';
import Spinner from '../component/Spinner';
import { getBuildStatusData } from '../api/auth';
import dayjs from 'dayjs';

function BuildStatusMiFront() {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const navigate = useNavigate();

    const [isShow, setIsShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [date, setDate] = useState('');
    const [dataHeader, setDataHeader] = useState(null);
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [data3, setData3] = useState(null);

    useEffect(() => {
        if (!activeAccount) {
            navigate("/");
        }
    }, [activeAccount]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [refresh]);

    useEffect(() => {
        // Set up an interval to fetch data every 30 seconds
        const intervalId = setInterval(() => {
            fetchData();
        }, 1000 * 30); // 30 seconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const fetchData = async () => {
        try {
            setIsShow(true);
            const result = await getBuildStatusData("MI");
            console.log('result', result);
            if (result.ok === true) {
                setDataHeader(result?.data?.filter(v => v.ITEM === "DACJ"));
                setData(result?.data?.filter(v => v.ROW_IDX < 8));
                setData2(result?.data?.filter(v => v.ITEM?.includes("INTRANSIT") || v.ITEM?.includes("EXTERNAL")));
                setData3(result?.data?.filter(v => v.ITEM === "FRT ALIGNER"));
                setDate(result.date);
            } else {
                alert("API CALL FAIL");
            }
        } catch (error) {
            console.log('error', error);
            alert(error.message);
        } finally {
            setIsShow(false);
        }
    };

    return (
        <>
            <Spinner isShow={isShow} />
            <Header setRefresh={setRefresh} />
            <div className='p-1 text-md bg-white dark:bg-black text-black dark:text-white'>
                <div className="flex flex-row justify-between">
                    <div className="font-bold">
                        Build Status - Front (MI)
                    </div>
                    <div className={`${((new Date().getTime() - new Date(date).getTime()) / (1000 * 60)) > 10 ? "bg-red-500" : ""}`}>
                        {dayjs(date).format('MMM, D YYYY(ddd) HH:mm A')}
                    </div>
                </div>
                <div className="relative overflow-x-auto mb-2">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            <tr className="bg-gray-300 dark:bg-[#1D3FA3]">
                                <th scope="col" rowSpan={2} className="border py-1" style={{ width: '25%' }}>
                                    DACJ
                                </th>
                                <th scope="col" className="border py-1" style={{ width: '25%' }}>
                                    CNT/Shift
                                </th>
                                <th scope="col" className="border py-1" style={{ width: '25%' }}>
                                    CNT/Hour
                                </th>
                                <th scope="col" className="border py-1" style={{ width: '25%' }}>
                                    JPH
                                </th>
                            </tr>
                            <tr>
                                <td className="border py-1" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE01}
                                </td>
                                <td className="border py-1" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE02}
                                </td>
                                <td className="border py-1" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE03}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="relative overflow-x-auto">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <thead className="text-xs bg-white dark:bg-black text-black dark:text-white ">
                            <tr className="bg-gray-300 dark:bg-[#1D3FA3]">
                                <th scope="col" className="border py-1">

                                </th>
                                <th scope="col" className="border py-1">
                                    {data?.[0]?.VALUE01}
                                </th>
                                <th scope="col" className="border py-1">
                                    {data?.[0]?.VALUE02}
                                </th>
                                <th scope="col" className="border py-1">
                                    {data?.[0]?.VALUE03}
                                </th>
                                <th scope="col" className="border py-1">
                                    {data?.[0]?.VALUE04}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            {data && data.map((v, i) => {
                                let bgVALUE01, bgVALUE02, bgVALUE03, bgVALUE04 = "";
                                if (v.ITEM === "GAP") {
                                    bgVALUE01 = v.VALUE01 < 0 ? "bg-red-500" : "";
                                    bgVALUE02 = v.VALUE02 < 0 ? "bg-red-500" : "";
                                    bgVALUE03 = v.VALUE03 < 0 ? "bg-red-500" : "";
                                    bgVALUE04 = v.VALUE04 < 0 ? "bg-red-500" : "";
                                }

                                return i > 0 ? (
                                    <tr key={i} className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                        <td scope="row" className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`}
                                            style={{ width: '36%' }}>
                                            {v.ITEM}
                                        </td>
                                        <td className={`border py-1 ${bgVALUE01}`} style={{ width: '16%' }}>
                                            {v.VALUE01}
                                        </td>
                                        <td className={`border py-1 ${bgVALUE02}`} style={{ width: '16%' }}>
                                            {v.VALUE02}
                                        </td>
                                        <td className={`border py-1 ${bgVALUE03}`} style={{ width: '16%' }}>
                                            {v.VALUE03}
                                        </td>
                                        <td className={`border py-1 ${bgVALUE04}`} style={{ width: '16%' }}>
                                            {v.VALUE04}
                                        </td>
                                    </tr>
                                ) : null
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="relative overflow-x-auto">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            {data2 && data2.map((v, i) => {
                                const isExternal = v.ITEM?.split("-")?.[0] === "EXTERNAL";

                                let bgVALUE01 = "", bgVALUE02 = "", bgVALUE03 = "", bgVALUE04 = "";
                                if (isExternal) {
                                    const VALUE01 = parseInt(data2?.[i]?.VALUE01?.toString()?.split("/")?.[0]) + parseInt(data2?.[i + 1]?.VALUE01?.toString()?.split("/")?.[0]);
                                    const VALUE02 = parseInt(data2?.[i]?.VALUE02?.toString()?.split("/")?.[0]) + parseInt(data2?.[i + 1]?.VALUE02?.toString()?.split("/")?.[0]);
                                    const VALUE03 = parseInt(data2?.[i]?.VALUE03?.toString()?.split("/")?.[0]) + parseInt(data2?.[i + 1]?.VALUE03?.toString()?.split("/")?.[0]);
                                    const VALUE04 = parseInt(data2?.[i]?.VALUE04?.toString()?.split("/")?.[0]) + parseInt(data2?.[i + 1]?.VALUE04?.toString()?.split("/")?.[0]);

                                    bgVALUE01 = VALUE01 === 0 ? "" : VALUE01 <= 66 ? "bg-red-500" : VALUE01 <= 99 ? "bg-yellow-500" : "";
                                    bgVALUE02 = VALUE02 === 0 ? "" : VALUE02 <= 66 ? "bg-red-500" : VALUE02 <= 99 ? "bg-yellow-500" : "";
                                    bgVALUE03 = VALUE03 === 0 ? "" : VALUE03 <= 66 ? "bg-red-500" : VALUE03 <= 99 ? "bg-yellow-500" : "";
                                    bgVALUE04 = VALUE04 === 0 ? "" : VALUE04 <= 66 ? "bg-red-500" : VALUE04 <= 99 ? "bg-yellow-500" : "";
                                }

                                return i % 2 === 0 ? (
                                    <React.Fragment key={i}>
                                        <tr className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                            <td rowSpan={2} className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`} style={{ width: '26%' }}>
                                                {v.ITEM?.split("-")?.[0]}
                                            </td>
                                            <td className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`} style={{ width: '10%' }}>
                                                {v.ITEM?.split("-")?.[1]}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE01}`} style={{ width: '16%' }}>
                                                {v.VALUE01}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE02}`} style={{ width: '16%' }}>
                                                {v.VALUE02}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE03}`} style={{ width: '16%' }}>
                                                {v.VALUE03}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE04}`} style={{ width: '16%' }}>
                                                {v.VALUE04}
                                            </td>
                                        </tr>
                                        <tr className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                            <td className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`} style={{ width: '10%' }}>
                                                {data2[i + 1].ITEM?.split("-")?.[1]}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE01}`} style={{ width: '16%' }}>
                                                {data2[i + 1].VALUE01}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE02}`} style={{ width: '16%' }}>
                                                {data2[i + 1].VALUE02}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE03}`} style={{ width: '16%' }}>
                                                {data2[i + 1].VALUE03}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE04}`} style={{ width: '16%' }}>
                                                {data2[i + 1].VALUE04}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ) : null
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="relative overflow-x-auto mt-2">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <thead className="text-xs bg-white dark:bg-black text-black dark:text-white ">
                            <tr className="bg-gray-300 dark:bg-[#1D3FA3]">
                                <th scope="col" className="border py-1">

                                </th>
                                <th scope="col" className="border py-1">
                                    A
                                </th>
                                <th scope="col" className="border py-1">
                                    B
                                </th>
                                <th scope="col" className="border py-1">
                                    C
                                </th>
                                <th scope="col" className="border py-1">
                                    D
                                </th>
                                <th scope="col" className="border py-1">
                                    E
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            {data3 && data3.map((v, i) => {
                                return (
                                    <tr key={i} className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                        <td scope="row" className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`}
                                            style={{ width: '16%' }}>
                                            Aligner Time
                                        </td>
                                        <td className="border py-1" style={{ width: '16%' }}>
                                            {v.VALUE01}
                                        </td>
                                        <td className="border py-1" style={{ width: '16%' }}>
                                            {v.VALUE02}
                                        </td>
                                        <td className="border py-1" style={{ width: '16%' }}>
                                            {v.VALUE03}
                                        </td>
                                        <td className="border py-1" style={{ width: '16%' }}>
                                            {v.VALUE04}
                                        </td>
                                        <td className="border py-1" style={{ width: '16%' }}>
                                            {v.VALUE05}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default BuildStatusMiFront;
