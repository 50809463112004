import React, { useEffect, useState } from 'react';

import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import Header from '../component/Header';
import Spinner from '../component/Spinner';
import { getBuildStatusData } from '../api/auth';
import dayjs from 'dayjs';

function BuildStatusMiRear() {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const navigate = useNavigate();

    const [isShow, setIsShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [date, setDate] = useState('');
    const [dataHeader, setDataHeader] = useState(null);
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [data3, setData3] = useState(null);

    useEffect(() => {
        if (!activeAccount) {
            navigate("/");
        }
    }, [activeAccount]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [refresh]);

    useEffect(() => {
        // Set up an interval to fetch data every 30 seconds
        const intervalId = setInterval(() => {
            fetchData();
        }, 1000 * 30); // 30 seconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const fetchData = async () => {
        try {
            setIsShow(true);
            const result = await getBuildStatusData("MI");
            console.log('result', result);
            if (result.ok === true) {
                setDataHeader(result?.data?.filter(v => v.ITEM === "DACJ"));
                setData(result?.data?.filter(v => v.ROW_IDX < 8));
                setData2(result?.data?.filter(v => v.ITEM?.includes("INTRANSIT") || v.ITEM?.includes("EXTERNAL")));
                setData3(result?.data?.filter(v => v.ITEM === "RR ALIGNER"));
                setDate(result.date);
            } else {
                alert("API CALL FAIL");
            }
        } catch (error) {
            console.log('error', error);
            alert(error.message);
        } finally {
            setIsShow(false);
        }
    }

    return (
        <>
            <Spinner isShow={isShow} />
            <Header setRefresh={setRefresh} />
            <div className='p-1 text-md bg-white dark:bg-black text-black dark:text-white'>
                <div className="flex flex-row justify-between">
                    <div className="font-bold">
                        Build Status - Rear (MI)
                    </div>
                    <div className={`${((new Date().getTime() - new Date(date).getTime()) / (1000 * 60)) > 10 ? "bg-red-500" : ""}`}>
                        {dayjs(date).format('MMM, D YYYY(ddd) HH:mm A')}
                    </div>
                </div>
                <div className="relative overflow-x-auto mb-2">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            <tr className="bg-gray-300 dark:bg-[#1D3FA3]">
                                <th scope="col" rowSpan={2} className="border py-1" style={{ width: '25%' }}>
                                    DACJ
                                </th>
                                <th scope="col" className="border py-1" style={{ width: '25%' }}>
                                    CNT/Shift
                                </th>
                                <th scope="col" className="border py-1" style={{ width: '25%' }}>
                                    CNT/Hour
                                </th>
                                <th scope="col" className="border py-1" style={{ width: '25%' }}>
                                    JPH
                                </th>
                            </tr>
                            <tr>
                                <td className="border py-1" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE01}
                                </td>
                                <td className="border py-1" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE02}
                                </td>
                                <td className="border py-1" style={{ width: '25%' }}>
                                    {dataHeader?.[0]?.VALUE03}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="relative overflow-x-auto">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <thead className="text-xs bg-white dark:bg-black text-black dark:text-white ">
                            <tr className="bg-gray-300 dark:bg-[#1D3FA3]">
                                <th scope="col" className="border py-1">

                                </th>
                                <th scope="col" className="border py-1">
                                    {data?.[0]?.VALUE05}
                                </th>
                                <th scope="col" className="border py-1">
                                    {data?.[0]?.VALUE06}
                                </th>
                                <th scope="col" className="border py-1">
                                    {data?.[0]?.VALUE07}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            {data && data.map((v, i) => {
                                let bgVALUE05, bgVALUE06, bgVALUE07 = "";
                                if (v.ITEM === "GAP") {
                                    bgVALUE05 = v.VALUE05 < 0 ? "bg-red-500" : "";
                                    bgVALUE06 = v.VALUE06 < 0 ? "bg-red-500" : "";
                                    bgVALUE07 = v.VALUE07 < 0 ? "bg-red-500" : "";
                                }

                                return i > 0 ? (
                                    <tr key={i} className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                        <td scope="row" className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`}
                                            style={{ width: '40%' }}>
                                            {v.ITEM}
                                        </td>
                                        <td className={`border py-1 ${bgVALUE05}`} style={{ width: '20%' }}>
                                            {v.VALUE05}
                                        </td>
                                        <td className={`border py-1 ${bgVALUE06}`} style={{ width: '20%' }}>
                                            {v.VALUE06}
                                        </td>
                                        <td className={`border py-1 ${bgVALUE07}`} style={{ width: '20%' }}>
                                            {v.VALUE07}
                                        </td>
                                    </tr>
                                ) : null
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="relative overflow-x-auto">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            {data2 && data2.map((v, i) => {
                                const isExternal = v.ITEM?.split("-")?.[0] === "EXTERNAL";

                                let bgVALUE05 = "", bgVALUE06 = "", bgVALUE07 = "";
                                if (isExternal) {
                                    const VALUE05 = parseInt(data2?.[i]?.VALUE05?.toString()?.split("/")?.[0]) + parseInt(data2?.[i + 1]?.VALUE05?.toString()?.split("/")?.[0]);
                                    const VALUE06 = parseInt(data2?.[i]?.VALUE06?.toString()?.split("/")?.[0]) + parseInt(data2?.[i + 1]?.VALUE06?.toString()?.split("/")?.[0]);
                                    const VALUE07 = parseInt(data2?.[i]?.VALUE07?.toString()?.split("/")?.[0]) + parseInt(data2?.[i + 1]?.VALUE07?.toString()?.split("/")?.[0]);

                                    bgVALUE05 = VALUE05 === 0 ? "" : VALUE05 <= 66 ? "bg-red-500" : VALUE05 <= 99 ? "bg-yellow-500" : "";
                                    bgVALUE06 = VALUE06 === 0 ? "" : VALUE06 <= 66 ? "bg-red-500" : VALUE06 <= 99 ? "bg-yellow-500" : "";
                                    bgVALUE07 = VALUE07 === 0 ? "" : VALUE07 <= 66 ? "bg-red-500" : VALUE07 <= 99 ? "bg-yellow-500" : "";
                                }

                                return i % 2 === 0 ? (
                                    <React.Fragment key={i}>
                                        <tr className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                            <td rowSpan={2} className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`} style={{ width: '27%' }}>
                                                {v.ITEM?.split("-")?.[0]}
                                            </td>
                                            <td className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`} style={{ width: '13%' }}>
                                                {v.ITEM?.split("-")?.[1]}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE05}`} style={{ width: '20%' }}>
                                                {v.VALUE05}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE06}`} style={{ width: '20%' }}>
                                                {v.VALUE06}
                                            </td>
                                            <td rowSpan={2} className={`border py-1 ${bgVALUE07}`} style={{ width: '20%' }}>
                                                {v.VALUE07}
                                            </td>
                                        </tr>
                                        <tr className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                            <td className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`} style={{ width: '13%' }}>
                                                {data2[i + 1].ITEM?.split("-")?.[1]}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE05}`} style={{ width: '20%' }}>
                                                {data2[i + 1].VALUE05}
                                            </td>
                                            <td className={`border py-1 ${bgVALUE06}`} style={{ width: '20%' }}>
                                                {data2[i + 1].VALUE06}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ) : null
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="relative overflow-x-auto mt-2">
                    <table className="w-full text-md text-center text-gray-500 border border-collapse border-white">
                        <thead className="text-xs bg-white dark:bg-black text-black dark:text-white ">
                            <tr className="bg-gray-300 dark:bg-[#1D3FA3]">
                                <th scope="col" className="border py-1">

                                </th>
                                <th scope="col" className="border py-1">
                                    A
                                </th>
                                <th scope="col" className="border py-1">
                                    B
                                </th>
                                <th scope="col" className="border py-1">
                                    C
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-black text-black dark:text-white">
                            {data3 && data3.map((v, i) => {
                                return (
                                    <tr key={i} className={`bg-white dark:bg-black text-black dark:text-white border-b`}>
                                        <td scope="row" className={`border py-1 font-medium bg-gray-300 dark:bg-[#1D3FA3]`}
                                            style={{ width: '25%' }}>
                                            Aligner Time
                                        </td>
                                        <td className="border py-1" style={{ width: '25%' }}>
                                            {v.VALUE01}
                                        </td>
                                        <td className="border py-1" style={{ width: '25%' }}>
                                            {v.VALUE02}
                                        </td>
                                        <td className="border py-1" style={{ width: '25%' }}>
                                            {v.VALUE03}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default BuildStatusMiRear;
