import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import { persistRootStore } from './rootReducer';

const store = configureStore({
    reducer: persistRootStore,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
    devTools: process.env.NODE_ENV !== 'production',
});

export const useAppDispatch = () => useDispatch();

export default store;

export const persistor = persistStore(store);
