import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { client } from '../../api/auth';

const initialState = {
    id: '',
    token: '',
    exp: null,
    name: '',
    role: '',
    loading: false,
    loadingMsg: 'Loading...',
    isNotification: false,
};

export const loginApi = createAsyncThunk('loginApi', async ({ id, password }, { rejectWithValue }) => {
    try {
        const response = await client.post('/auth/login', {
            id,
            password,
        });
        // console.log('[LoginApi]Success', response.data);
        return response.data;
    } catch (err) {
        // console.log('[LoginApi]Error', err.response.data.err);
        return rejectWithValue({
            message: err.response?.data?.err ?? err.message,
        });
        // throw new Error(err.response?.data?.err ?? err.message);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        resetState(state, action) {
            state = { ...initialState };
            localStorage.removeItem('persist:store');
        },
        setLoading(state, action) {
            state.loading = action.payload.loading;
            state.loadingMsg = action.payload.loadingMsg;
        },
        setToken(state, action) {
            state.token = action.payload.token;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginApi.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.name = payload.name;
                state.token = payload.token;
                state.exp = payload.exp;
                state.role = payload.role ?? '';
                state.isNotification = payload.isNotification;
            })
            .addCase(loginApi.rejected, (state, action) => {
                console.log(state, action);
                state.loading = false;
            });
    },
});

export const { resetState, setLoading, setToken } = userSlice.actions;

export default userSlice.reducer;
