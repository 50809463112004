import React, { useEffect, useState } from 'react';

import { Oval } from 'react-loader-spinner'

function Spinner({ isShow }) {

    return (
        <>
            {isShow && (
                <div className="z-50 absolute w-full h-full flex justify-center items-center bg-gray-400 bg-opacity-50">
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="black"
                        secondaryColor="gray"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
        </>
    )
}

export default Spinner;
